import React, { createContext, useContext, useState, useEffect } from 'react';
import { getWeeksAroundDate } from '@/lib/api';

export const WeekContext = createContext({});

export const WeekProvider = ({ children }) => {
    const [currentWeek, setCurrentWeek] = useState(null);
    const [currentWeekId, setCurrentWeekId] = useState(null);
    const [currentWeekStartDate, setCurrentWeekStartDate] = useState(null);
    const [isCurrentWeek, setIsCurrentWeek] = useState(true);
    const [isCurrentWeekLoading, setIsCurrentWeekLoading] = useState(true);

    const [selectedWeekId, setSelectedWeekId] = useState(null);
    const [selectedWeekStartDate, setSelectedWeekStartDate] = useState(null);

    const [adjacentWeeks, setAdjacentWeeks] = useState({});

    useEffect(() => {
        const fetchInitialWeeks = async () => {
            const response = await getWeeksAroundDate();
            setCurrentWeek(response.currentWeek);
            setCurrentWeekId(response.currentWeek?.id);
            setSelectedWeekId(response.currentWeek?.id);
            setSelectedWeekStartDate(response.currentWeek?.week_start_date);
            setCurrentWeekStartDate(response.currentWeek?.week_start_date);
            setAdjacentWeeks(response);
        };

        fetchInitialWeeks();
    }, []);

    const contextValues = {
        isCurrentWeek,
        setIsCurrentWeek,
        currentWeekId,
        setCurrentWeekId,
        currentWeek,
        currentWeekStartDate,
        setCurrentWeekStartDate,

        selectedWeekId,
        setSelectedWeekId,
        selectedWeekStartDate,
        setSelectedWeekStartDate,

        adjacentWeeks,
        setAdjacentWeeks,

        isCurrentWeekLoading,
        setIsCurrentWeekLoading
    };

    return (
        <WeekContext.Provider value={contextValues}>
            {children}
        </WeekContext.Provider>
    );
};

export const useWeek = () => useContext(WeekContext);
