import React, {createContext, useContext, useState} from 'react';

export const PublicationContext = createContext({});

export const PublicationProvider = ({ children }) => {
    const [publications, setPublications] = useState([]);
    const [ publicationsLoading, setPublicationsLoading ] = useState(true);

    const contextValues = {
        publications,
        setPublications,
        publicationsLoading,
        setPublicationsLoading
    };

    return (
        <PublicationContext.Provider value={contextValues}>
            {children}
        </PublicationContext.Provider>
    );
};

export const usePublication = () => useContext(PublicationContext);
