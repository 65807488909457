import React, {createContext, useContext, useEffect, useState} from 'react';
import {useSession} from 'next-auth/react';
import {getMyFollowedPublicationIds, toggleFollowPublication} from '@/lib/api';
import {toast} from 'react-toastify';

export const FollowContext = createContext();

export const FollowingProvider = ({ children }) => {
    const { data: session, status } = useSession();

    const [followedPublications, setFollowedPublications] = useState([]);
    const [publications, setPublications] = useState([]);
    const [weeklyEmailDay, setWeeklyEmailDay] = useState('');
    const [weeklyEmailTime, setWeeklyEmailTime] = useState('');
    const [weeklyEmailTimeZone, setWeeklyEmailTimeZone] = useState('');
    const [filteredFollowingPrintoutCount, setFilteredFollowingPrintoutCount] = useState(0);

    useEffect(() => {
        if (session) {
            getMyFollowedPublicationIds(session.user.id).then(res => {
                setFollowedPublications(res);
            });
        }
    }, []);


    const toggleFollowings = async (publicationId, type) => {
        const data = {
            userId: session.user.id,
            publicationId
        };

        const shouldFollow = type === 'follow';

        try {
            await toggleFollowPublication(data, shouldFollow);

            const updatedItems = !shouldFollow ? followedPublications.filter(itemId => itemId !== publicationId) : [publicationId, ...followedPublications];
            setFollowedPublications(updatedItems);
            setFilteredFollowingPrintoutCount(updatedItems.length);

            toast(`${shouldFollow ? 'Started following publication' : 'Unfollowed publication'}`, { type: shouldFollow ? 'success' : 'info' });
            return true;
        } catch (error) {
            toast.error('Failed to follow publication');
            return false;
        }

    };

    const isPublicationFollowed = (followingArray, publicationIdToCheck) => {
        if (!session) {
            return false;
        }

        return followingArray.some(publicationId => publicationId === publicationIdToCheck);
    }

    const contextValues = {
        toggleFollowings,
        isPublicationFollowed,

        followedPublications,
        setFollowedPublications,
        filteredFollowingPrintoutCount,
        setFilteredFollowingPrintoutCount,
        publications,
        setPublications,
        weeklyEmailDay,
        setWeeklyEmailDay,
        weeklyEmailTime,
        setWeeklyEmailTime,
        weeklyEmailTimeZone,
        setWeeklyEmailTimeZone
    };

    return (
        <FollowContext.Provider value={contextValues}>
            {children}
        </FollowContext.Provider>
    );
};

export const useFollowing = () => useContext(FollowContext);
