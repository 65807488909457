import React, {createContext, useContext, useEffect, useState} from 'react';
import {useSession} from 'next-auth/react';
import {toast} from 'react-toastify';
import {getMyBookmarkedPrintoutIds, toggleBookmarkPrintout} from '@/lib/api';

export const BookmarkContext = createContext();

export const BookmarkProvider = ({ children }) => {
    const { data: session, status } = useSession();

    const [bookmarkedPrintouts, setBookmarkedPrintouts] = useState([]);

    useEffect(() => {
        if (session) {
            getMyBookmarkedPrintoutIds(session.user.id).then(res => {
                setBookmarkedPrintouts(res);
            });
        }
    }, []);

    const toggleBookmarks = async (printoutId, type) => {
        const data = {
            userId: session.user.id,
            printoutId
        };

        const isAdding = type === 'add';

        try {
            await toggleBookmarkPrintout(data, isAdding);

            toast(`Bookmark ${isAdding ? 'added' : 'removed'}`, { type: isAdding ? 'success' : 'info' });
            return true;

        } catch (error) {
            toast.error('Failed to bookmark printout');
            return false;
        }


    };

    const isPrintoutBookmarked = (bookmarkArray, printoutIdToCheck) => {
        if (!session) {
            return false;
        }

        return bookmarkArray.some(printoutId => printoutId === printoutIdToCheck);
    }

    const contextValues = {
        toggleBookmarks,
        isPrintoutBookmarked,
        bookmarkedPrintouts,
        setBookmarkedPrintouts
    };

    return (
        <BookmarkContext.Provider value={contextValues}>
            {children}
        </BookmarkContext.Provider>
    );
};

export const useBookmark = () => useContext(BookmarkContext);
