import "bootstrap/dist/css/bootstrap.min.css";
import '@/styles/globals.css'
import '@/styles/style.css'
import '@/styles/DropZone.css'
import { SessionProvider } from 'next-auth/react';
import {AppProvider} from '@/context/AppContext';
import {BookmarkProvider} from '@/context/BookmarkContext';
import {FollowingProvider} from '@/context/FollowingContext';
import {PrintoutProvider} from '@/context/PrintoutContext';
import {PublicationProvider} from '@/context/PublicationContext';
import {WeekProvider} from '@/context/WeekContext';


function MyApp({ Component, pageProps }) {
    return (
        <SessionProvider session={pageProps.session}>
            <AppProvider>
                <WeekProvider>
                    <PublicationProvider>
                        <PrintoutProvider>
                            <FollowingProvider>
                                <BookmarkProvider>
                                    <Component {...pageProps} />
                                </BookmarkProvider>
                            </FollowingProvider>
                        </PrintoutProvider>
                    </PublicationProvider>
                </WeekProvider>
            </AppProvider>
        </SessionProvider>
    )
}


export default MyApp
